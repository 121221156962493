import { connectMenu } from "react-instantsearch-dom"
import React, { useState, Fragment, useEffect } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const MobileDropdownS = ({ label, items, currentRefinement, refine, hide }) => {


  const [selected, setSelected] = useState(items[0])



  if (hide) return null
 // filter updates as the user selects an item
  return (
    <div class="flex flex-col px-4">
        <div>
        <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="flex items-center">
                <span className="ml-3 text-xs block truncate">{
                  currentRefinement ? currentRefinement : "Select"}
                </span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {items.map((item) => (
                  <Listbox.Option
                    key={item.label}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={item.label}
                  >
                   {({ selected, active }) => (
                <>
                  <span
                    className={`${
                      selected ? 'font-medium text-white' : 'font-normal'
                    } block truncate`}
                    onClick={() => refine(item.value)}
                    // Change currentLabel to the selected item



                  >
                    {item.label}
                  </span>
                  {selected ? (
                    <span
                      className={`${
                        active ? 'text-amber-600' : 'text-amber-600'
                      }
                            absolute inset-y-0 right-4 flex items-center pl-3`}
                    >
                      <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
        </div>
    </div>
  )
}

const MobileDropdownSelect = connectMenu(MobileDropdownS)

export default MobileDropdownSelect
